@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* 
 * here we add some preflight styles to normalize the browser defaults
 */

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

/**
 * embedded widget to Jira Service Desk
 * https://infworks.atlassian.net/jira/servicedesk/projects/RSP/settings/widget
 */
iframe[name="JSD widget"] {
  left: 0px !important;
  bottom: -12px !important;
  right: auto !important;
  top: auto !important;
  z-index: 1300 !important;
}
